<template>
    <div>
      <label class="block text-gray-700 text-sm font-bold mb-1" :for="label">
        {{ label }}
      </label>
      <input
        v-model="inputModel"
        :class="`
          appearance-none
          ${type==='file'?'':'border focus:outline-none focus:shadow-outline'}
          rounded
          w-full
          py-2
          px-3
          text-gray-700
          leading-tight
          `"
        :id="label"
        autocomplete="off"
        :accept="accept"
        :type="type"
        :placeholder="label"
        :required="required"
      />
    </div>
  </template>
  
  <script>
  export default {
    props: {
      label: String,
      type: String,
      accept: String,
      required: Boolean,
      modelValue: {
        default: ''
      }
    },
    computed: {
      inputModel: {
        get() {
          return this.modelValue;
        },
        set(value) {
          this.$emit('update:modelValue', value);
        }
      }
    }
  };
  </script>
  
  <style>
  </style>
  