<template>
    <div class="bg-wildert h-screen w-screen p-3 flex items-stretch">
        <div class="bg-white rounded-lg h-full w-full flex">
            <div class="w-1/2 border-r p-3 flex flex-col space-y-2">
                <div class="
                flex
                appearance-none
                border 
                focus:outline-none 
                focus:shadow-outline
                rounded
                w-full
                text-gray-700
                leading-tight">
                    <div class="w-full">
                        <input v-model="searchstring" placeholder="Vorname, Nachname oder E-Mail"
                            class="h-full w-full py-2 focus:outline-none focus:shadow-outline px-3">
                    </div>
                    <div class=" w-10 bg-gray-100">
                        <MagnifyingGlassIcon />
                    </div>

                </div>
                <div class="overflow-y-scroll">
                    <div>
                        <button @click="selectRegistration(r.id)"
                            :class="`flex justify-start w-full p-3 cursor-pointer hover:bg-gray-300 ${idx % 2 == 1 ? 'bg-gray-100' : 'bg-gray-200'}`"
                            v-for="(r, idx) in fuseRegistrations" :key="r.id">
                            {{ r.firstname }} {{ r.lastname }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="w-1/2 flex flex-col p-3 items-center h-full">
                <object v-if="selected" class="rounded-lg h-1/2"
                    :data="`${backendPath}${selected.filepath}`" type="application/pdf" width="100%">
                </object>
                <div v-if="selected" class="pt-4 px-5 flex flex-col w-full h-1/2">
                    <div class="flex">
                        <div class="w-1/2">
                            <div> <span class="font-semibold">Vorname:</span></div>
                            <div class="text-xl font-light pl-2"> {{ selected.firstname }} </div>
                        </div>
                        <div class="w-1/2">
                            <div><span class="font-semibold">Nachname:</span></div>
                            <div class="text-xl font-light pl-2">{{ selected.lastname }} </div>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="w-1/2">
                            <div>
                        <span class="font-semibold">E-Mail:</span>
                    </div>
                    <div class="text-xl font-light pl-2"><a class="text-blue-500 hover:underline"
                            :href="`mailto:${selected.email}`">{{ selected.email }}</a>
                        </div>
                        </div>
                        <div class="w-1/2">
                            <div><span class="font-semibold">Erstellt:</span></div>
                            <div class="text-xl font-light pl-2">{{ formatDate(new Date(selected.created)) }} </div>
                           
                        </div>
                    </div>
                    
                </div>
                <div v-if="selected" class="self-baseline flex justify-end w-full"> <button @click="selectForDelete(selected.id)"><TrashIcon class="w-5 text-zinc-800"/></button></div>
                <div v-if="!selected" class="self-center">Klicke auf einen Namen</div>


            </div>

        </div>
    </div>
    <div v-if="toBeDeleted" class="absolute bg-black bg-opacity-40 top-0 right-0 z-10 w-full h-full flex justify-center items-center">
        <div class="bg-white rounded-lg w-1/3 p-5 flex flex-col space-y-2">
            <div>Willst du die Anmeldung wirklich löschen?</div>
            <div class="flex space-x-3">
                <button @click="deleteRegistration()" class="p-2 rounded-lg border border-gray-300">Ja</button>
                <button @click="toBeDeleted=undefined" class="p-2 rounded-lg border border-gray-300">Nein</button>
            </div>
        </div>
    </div>
</template>
<script>
import { MagnifyingGlassIcon, TrashIcon } from '@heroicons/vue/24/solid'
const { format } = require('date-fns');
import Fuse from 'fuse.js'
export default {
    components: { MagnifyingGlassIcon, TrashIcon },
    data() {
        return {
            registrations: [],
            selected: undefined,
            searchstring: "",
            fuse: undefined,
            fuseRegistrations: [],
            toBeDeleted: undefined
        }
    },
    methods: {
        selectRegistration(id) {
            this.selected = this.registrations.find(r => r.id == id)

        },
        async getRegistrations() {
            const response = await this.callApi("GET", "registrations")
            if(response.data.message){
                localStorage.removeItem("token")
                this.$router.push("login")
            }
            this.registrations = response.data
        },
        async deleteRegistration(){
            await this.callApi("DELETE", `registrations/${this.toBeDeleted}`)
            this.toBeDeleted = undefined
            this.selected = undefined
            await this.getRegistrations()
            this.fuse = new Fuse(this.registrations, { keys: ["firstname", "lastname", "email"] })
            this.fuseRegistrations = this.registrations

        },
        formatDate(date){
            return format(date, 'dd.MM.yyyy HH:mm')
        },
        selectForDelete(id){
            this.toBeDeleted = id
        },

    },
    computed: {
        backendPath(){
            return process.env.VUE_APP_BACKEND
        }
    },
    watch: {
        searchstring(newVal) {
            console.log(newVal)
            this.fuseRegistrations = this.fuse.search(newVal).map(i => i.item)
            if (this.fuseRegistrations.length == 0 && this.searchstring == "") {
                this.fuseRegistrations = this.registrations
            }
        }
    },
    async created() {
        await this.getRegistrations()
        this.fuse = new Fuse(this.registrations, { keys: ["firstname", "lastname", "email"] })
        this.fuseRegistrations = this.registrations


    }

}
</script>
