<template>
  <div class="bg-wildert min-h-screen min-w-screen flex justify-center items-start w-full">

    <div class="bg-white rounded-lg p-3 mx-1 mt-10 md:w-1/3 w-full flex flex-col space-y-2">
      <div class="flex">
        <img class=" h-36" src="@/assets/Wildert_Logo_Neu.svg" />
        <h1 class="font-semibold text-6xl">Wildert Anmeldung</h1>
      </div>
      
      <div v-if="successMessage" class="rounded-lg p-3 bg-green-400 border-green-600 font-semibold">
       {{successMessage}}
      </div>
      <div v-if="warningMessage" class="rounded-lg p-3 bg-red-400 border-red-600 font-semibold">
       {{warningMessage}}
      </div>
      <form id="form" class="space-y-2" @submit.prevent="submitForm" enctype="multipart/form-data">
        <InputField v-model="form.firstname" :required="true" label="Vorname des Kindes" />
        <InputField v-model="form.lastname"  :required="true" label="Nachname des Kindes" />
        <InputField v-model="form.email"  :required="true" label="Email" type="email" />
        <InputField v-model="form.file" label="Ausgefüllte Wildert Anmeldung im PDF-Format" type="file" accept="application/pdf" />
        <BasicButton>Abschicken</BasicButton>
      </form>
    </div>

  </div>
</template>

<script>
import BasicButton from '@/components/BasicButton.vue';
import InputField from '@/components/InputField.vue';



export default {
  name: 'HomeView',
  components: {
    InputField,
    BasicButton
  },
  data() {
    return {
      form: {
        firstname: undefined,
        lastname: undefined,
        email: undefined,
        file: undefined
      },
      warningMessage: undefined,
      successMessage: undefined
    }
  },
  methods: {
    async submitForm(event) {
      this.warningMessage = undefined
      this.successMessage = undefined
      const formData = new FormData();
      formData.append('firstname', this.form.firstname);
      formData.append('lastname', this.form.lastname);
      formData.append('email', this.form.email);
      const files = event.target.querySelector('input[type="file"]').files

      if (files.length>0) {
        if(files[0].type!="application/pdf"){
          this.warningMessage ="Die Anmeldung erfordert das PDF-Dateiformat!"
          return

        }
        formData.append('pdf', files[0]);
      }else{
        this.warningMessage ="Du hast die Anmeldung vergessen. Bitte füge eine Anmeldung im PDF-Format hinzu."
        return
      }

     
      await this.callApi('POST','registrations', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
      
            this.successMessage ="Vielen Dank für die Anmeldung 😁!"
      
            this.form = {}
          

    }
  }
}
</script>
