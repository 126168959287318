<template>
    <div class="bg-wildert min-h-screen min-w-screen flex justify-center items-start w-full">

        <div class="bg-white rounded-lg p-3 mx-1 mt-10 md:w-1/3 w-full flex flex-col space-y-2">
            <form class="flex flex-col space-y-2" @submit.prevent="login">
                <div v-if="message" class="rounded-lg p-3 bg-red-400 border-red-600 font-semibold">
                    {{ message }}
                </div>
                <InputField v-model="password" type="password" label="Passwort" />
                <BasicButton>Login</BasicButton>
            </form>
        </div>


    </div>
</template>
<script>
import BasicButton from '@/components/BasicButton.vue';
import InputField from '@/components/InputField.vue';

export default {
    components: { InputField, BasicButton },
    data() {
        return {
            password: undefined,
            message: ""
        }
    },
    methods: {
        async login() {
            const response = await this.callApi("POST", "login",{ password: this.password })
            if (response.data.token) {
                localStorage.setItem("token", response.data.token)
                this.$router.push("dashboard")
            } else {
                this.message = "Das Passwort ist nicht korrekt!"

            }


        }
    }
}
</script>