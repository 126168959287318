<template>
    <button class="border-2 border-black p-3 rounded-lg w-full hover:text-white hover:bg-black font-semibold">
<slot></slot>
</button>
</template>

<script>
export default {

}
</script>