import axios from 'axios'
const api = axios.create({
    baseURL: process.env.VUE_APP_BACKEND,
    headers:{
        'Accept': 'application/json'
    },
    timeout: 1000
  });
export const mixin = {
    methods:{
        async callApi(method, url, data){
            try{
                const headers = {
                    'Accept': 'application/json'
                }
                const token = localStorage.getItem("token");
                if(token){
                headers.Authorization= `Bearer ${token}`
                
                }   
                const response = await api({method,url,data, headers: headers  })
                console.log(response)
                return response
            }catch(e){
                console.log(e)
                return JSON.stringify(e, Object.getOwnPropertyNames(e))
            }

        }
    }
}